import "../css/homepage.css";
import "../css/app.css";
import "./bootstrap";

// scroll bar
import "simplebar/dist/simplebar.css";

// lightbox
import "react-18-image-lightbox/style.css";

// editor
import "react-quill/dist/quill.snow.css";


// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
// import 'react-lazy-load-image-component/src/effects/blur.css';

import { createRoot } from "react-dom/client";
import { createInertiaApp, router } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import SnackbarProvider from "./Components/snackbar/SnackbarProvider";
import ThemeProvider from "./theme";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const appName = import.meta.env.VITE_APP_NAME || "Upcare";

const userTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

router.on("before", (event) => {
    event.detail.visit.headers["X-User-Timezone"] = userTimezoneName;
});

createInertiaApp({
    title: (title) => `${appName} | ${title || "Home"}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider>
                    <SnackbarProvider>
                        <App {...props} />
                    </SnackbarProvider>
                </ThemeProvider>
            </LocalizationProvider>
        );
    },
    progress: {
        color: "#012970",
    },
});
