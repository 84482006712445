import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, width = 20, customWidth, sx, ...other }, ref) => {
  const iconWidth = customWidth ? customWidth : width;
  return (
    <Box ref={ref} component={Icon} icon={icon} sx={{ width: iconWidth, height: iconWidth, ...sx }} {...other} />
  )
}
);

Iconify.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Iconify;
