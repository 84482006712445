import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
// components
// import { useSettingsContext } from '../Components/settings';
//
import palette from "./palette";
import typography from "./typography";
import shadows from "./shadows";
import customShadows from "./customShadows";
import componentsOverride from "./overrides";
import GlobalStyles from "./globalStyles";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
    children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
    // const { themeMode, themeDirection } = useSettingsContext();

    const themeMode = "light";
    const themeDirection = "ltr";

    const themeOptions = useMemo(
        () => ({
            palette: palette(themeMode),
            typography: {
                fontFamily: "Poppins, sans-serif",
                h1: {
                    fontFamily: "Roboto, sans-serif",
                },
                h2: {
                    fontFamily: "Roboto, sans-serif",
                },
                h3: {
                    fontFamily: "Roboto, sans-serif",
                },
                h4: {
                    fontFamily: "Roboto, sans-serif",
                },
                h5: {
                    fontFamily: "Roboto, sans-serif",
                },
                h6: {
                    fontFamily: "Roboto, sans-serif",
                },
            },
            shape: { borderRadius: 8 },
            // direction: themeDirection,
            direction: "ltr",
            shadows: shadows(themeMode),
            customShadows: customShadows(themeMode),
        }),
        [themeDirection, themeMode]
    );

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
