import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop (theme) {
	return {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: alpha(theme.palette.grey[800], 0.8),
					zIndex: theme.zIndex.drawer + 10
				},
				invisible: {
					background: 'transparent',
				},
			},
		},
	};
}
